import AuthService from "../../services/AuthService";

//state
const state = () => ({
  maps : [],
  api_url: process.env.VUE_APP_API_ROOT+"/api/maps"
})

// getters
const getters = {
  maps: state => state.maps,
  mapsInStock: state => state.maps.filter (el => el.inStock === true),
  mapsByAtId: state => atId => state.maps.filter(map => map['@id'] === atId),
}

//actions
const actions = {
  loadMaps({dispatch, commit, state}){
    return new Promise((resolve, reject) =>{
      dispatch('tools/getFromAPI', {url: state.api_url+'?pagination=false'}, {root: true})
        .then((response) => {
          if(response.status === 200){
            commit('replaceMaps', response.data['hydra:member']);
            resolve(response.status);
          } else {
            reject(response.status)
          }
        })
        .catch((error)=>{
          reject(error);
        })
    })

  },
  addMap({dispatch, commit, state}, map){
    return new Promise((resolve, reject) => {
      dispatch('tools/postToAPI', {url:state.api_url, data:map}, {root: true})
        .then((response)=>{
          if(response.status === 201){
            commit('pushToMaps',response.data);
            resolve(response.status);
          }else{
            reject(response.status);
          }
        })
        .catch((error)=>{
          reject(error);
        })
    })

  },
  deleteMap({dispatch, commit, state}, mapId){
    return new Promise((resolve, reject)=>{
      dispatch('tools/deleteFromAPI',{url:state.api_url+'/'+mapId}, {root: true})
        .then((response)=>{
          if(response.status === 204){
            commit('removeFromMaps', mapId);
            resolve(response.status)
          }else{
            reject(response.status)
          }
        })
        .catch((error) => {
          reject(error);
        });
    })
  },
  updateMap({dispatch, commit, state}, payload){
    return new Promise((resolve, reject) => {
      dispatch('tools/putToAPI',{url: state.api_url+'/'+payload.mapId, data:payload.map}, {root: true})
        .then((response) => {
          if(response.status === 200){
            commit('removeFromMaps',response.data.id);
            commit('pushToMaps', response.data);
            resolve(response.status);
          }else{
            reject(response.status);
          }
        })
        .catch((error)=>{
          reject(error);
        })
    })

  },

}

//mutations
const mutations = {
  replaceMaps(state, maps){
    state.maps = maps;
  },
  pushToMaps(state, map) {
    state.maps.push(map);
  },
  removeFromMaps(state, mapId) {
    state.maps = state.maps.filter( el => el.id !== mapId);
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
