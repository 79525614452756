import Vue from 'vue'
import Vuex from 'vuex'

import maps from './module/maps';
import tracks from "./module/tracks";
import activities from "./module/activities";
import directions from "./module/directions";
import statuses from "./module/statuses";
import users from "./module/users";
import projects from "./module/projects";
import tools from "./module/tools";
import scheduledTracks from "./module/scheduledTracks";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    maps,
    tracks,
    activities,
    directions,
    statuses,
    users,
    projects,
    tools,
    scheduledTracks,
  },
  strict: debug
})
