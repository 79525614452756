import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import store from '@/store/store';

Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  mode:'history',
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior: (to, from ,savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next)=>{
  const { authorize } = to.meta;
  if (authorize) {
    store.dispatch("users/getCurrentUser")
      .then(()=>{
        let currentUser = store.getters["users/me"];

        if (!currentUser.firstName) {
          // not logged in so redirect to login page with the return url
          return next({ path: '/login', query: { returnUrl: to.path } });
        }

        if(!currentUser.roles.length){
          return next({path: '/login'})
        }

        if (authorize.length && !currentUser.roles.includes(authorize[0])) {
          // role not authorised so redirect to home page
          return next({ path: '/' });
        }
      })
      .catch( () => {return next({path: '/login'})} )
  }
  next();
});

export default router;
