import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import AuthLayout from '@/views/Pages/AuthLayout.vue';
import AdminLayout from "../views/Layout/AdminLayout";
import NotFound from '@/views/NotFoundPage.vue';

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
    component: DashboardLayout,
    meta: { authorize: ['ROLE_USER'] }, 
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        meta: { authorize: ['ROLE_USER'] }, 
        component: () => import( '../views/Dashboard.vue')
      },
      {
        path: '/tracks',
        name: 'tracks',
        meta: { authorize: ['ROLE_USER'] }, 
        component: () => import( '../views/Tracks.vue')
      },
      {
        path: '/maps',
        name: 'maps',
        meta: { authorize: ['ROLE_USER'] }, 
        component: () => import( '../views/Maps.vue')
      },
      {
        path: '/settings',
        name: 'settings',
        meta: { authorize: ['ROLE_MANAGER'] }, 
        component: () => import( '../views/Settings.vue')
      },
      {
        path: '/projects',
        name: 'projects',
        meta: { authorize: ['ROLE_USER'] }, 
        component: () => import( '../views/Projects')
      }
    ]
  },
  {
    path: '/',
    redirect: '/admin/users',
    component: AdminLayout,
    meta: { authorize: ['ROLE_ADMIN'] }, 
    children: [
      {
        path:'/admin',
        redirect: '/admin/users',
        meta: { authorize: ['ROLE_ADMIN'] }, 
      },
      {
        path: '/admin/users',
        name: 'users',
        meta: { authorize: ['ROLE_ADMIN'] }, 
        component: () => import('../views/AdminUsers')
      }
    ]
  },
  {
    path: '/',
    redirect: 'login',
    component: AuthLayout,
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import( '../views/Pages/Login.vue')
      },
      {
        path: '/register',
        name: 'register',
        component: () => import( '../views/Pages/Register.vue')
      },
      { path: '*', component: NotFound }
    ]
  }
];

export default routes;
