import AuthService from "../../services/AuthService";

//state
const state = () => ({
  activities : [],
  activitiesStatistics : [],
  api_url: process.env.VUE_APP_API_ROOT+"/api/activities",
  api_url_statistics: process.env.VUE_APP_API_ROOT+"/api/activitiesstatistics",
})

// getters
const getters = {
  activities: state => state.activities,
  activitiesStatistics: state => state.activitiesStatistics,
}

//actions
const actions = {
  loadActivities({dispatch, commit, state}){
    return new Promise((resolve, reject) => {
      dispatch('tools/getFromAPI', {url: state.api_url},{root: true})
        .then((response) => {
          commit('replaceActivities', response.data['hydra:member']);
          resolve(response.status);
        })
        .catch((error)=>{
          if(error.response.status === 401) {
            AuthService.logout();
          }else{
            reject(error.response);
          }

        })
    })
  },
  addActivity({dispatch, commit, state}, activity){
    return new Promise((resolve, reject) => {
      dispatch('tools/postToAPI', {url: state.api_url, data:activity}, {root: true} )
        .then((response)=>{
          commit('pushToActivities',response.data);
          resolve(response);
        })
        .catch((error)=>{
          reject(error);
        })
    })

  },
  deleteActivity({dispatch, commit, state}, activityId){
    return new Promise((resolve, reject) => {
      dispatch('tools/deleteFromAPI',{url: state.api_url+'/'+activityId}, {root: true})
        .then((response)=>{
          if(response.status === 204){
            commit('removeFromActivities', activityId);
            resolve(response.status);
          } else {
           reject(response.status)
          }
        })
        .catch((error) => {
          reject(error);
        });
    })
  },
  updateActivity({dispatch, commit, state}, payload){
    return new Promise((resole, reject) => {
      dispatch('tools/putToAPI',{url:state.api_url+'/'+payload.activityId, data:payload.activity}, {root: true})
        .then((response) => {
          if(response.status === 200){
            commit('removeFromActivities',response.data.id);
            commit('pushToActivities', response.data);
            resole(response.status);
          } else {
            reject(response.status)
          }
        })
        .catch((error)=>{
          reject(error);
        })
    })
  },
  loadActivitiesStatistics({dispatch, commit, state}){
    return new Promise((resolve, reject) => {
      dispatch('tools/getFromAPI', {url: state.api_url_statistics},{root: true})
        .then((response) => {
          commit('replaceActivitiesStatistics', response.data);
          resolve(response.status);
        })
        .catch((error)=>{ reject(error.response); })
    })
  },
}

//mutations
const mutations = {
  replaceActivities(state, activities){
    state.activities = activities;
  },
  pushToActivities(state, activity) {
    state.activities.push(activity);
  },
  removeFromActivities(state, activityId) {
    state.activities = state.activities.filter( el => el.id !== activityId);
  },
  replaceActivitiesStatistics (state, activitiesStatistics) {
    state.activitiesStatistics = activitiesStatistics;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
