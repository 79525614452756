import AuthService from "../../services/AuthService";

//state
const state = () => ({
  directions : [],
  api_url: process.env.VUE_APP_API_ROOT+"/api/directions"
})

// getters
const getters = {
  directions: state => state.directions,
}

//actions
const actions = {
  loadDirections({dispatch, commit, state}){
    return new Promise((resolve, reject) => {
      dispatch('tools/getFromAPI', {url:state.api_url}, {root:true})
        .then((response) => {
          commit('replaceDirections', response.data['hydra:member']);
          resolve(response.status);
        })
        .catch((error)=>{
          if(error.response.status === 401) {
            AuthService.logout();
          }else{
            reject(error.response);
          }

        })
    })
  },
  addDirection({dispatch, commit, state}, direction){
    return new Promise((resolve, reject) => {
      dispatch('tools/postToAPI', {url:state.api_url, data:direction},  {root: true})
        .then((response)=>{
          if(response.status === 201){
            commit('pushToDirections',response.data);
            resolve(response.status);
          } else {
            reject(response.status)
          }
        })
        .catch((error)=>{
          reject(error);
        })
    })
  },
  deleteDirection({dispatch, commit, state}, activityId){
    return new Promise((resolve, reject) => {
      dispatch('tools/deleteFromAPI', {url:state.api_url+'/'+activityId}, {root:true})
        .then((response)=>{
          if(response.status === 204){
            commit('removeFromDirections', activityId);
            resolve(response.status);
          } else {
            reject(response.status)
          }
        })
        .catch((error) => {
          reject(error);
        });
    })
  },
  updateDirection({dispatch, commit, state}, payload){
    return new Promise((resole, reject) => {
      dispatch('tools/putToAPI', {url:state.api_url+'/'+payload.directionId, data:payload.direction}, {root: true})
        .then((response) => {
          if(response.status === 200){
            commit('removeFromDirections',response.data.id);
            commit('pushToDirections', response.data);
            resole(response.status);
          } else {
            reject(response.status)
          }
        })
        .catch((error)=>{
          reject(error);
        })
    })
  },
}

//mutations
const mutations = {
  replaceDirections(state, directions){
    state.directions = directions;
  },
  pushToDirections(state, map) {
    state.directions.push(map);
  },
  removeFromDirections(state, activityId) {
    state.directions = state.directions.filter( el => el.id !== activityId);
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
