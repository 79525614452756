import AuthService from "../../services/AuthService";
import {dispatch} from "d3";

//state
const state = () => ({
  scheduledTracks : [],
  api_url: process.env.VUE_APP_API_ROOT+"/api/scheduled_tracks"
})

// getters
const getters = {
  scheduledTracks : state => state.scheduledTracks
}

//actions
const actions = {
  loadScheduledTracks({dispatch, commit, state}, payload) {
    return new Promise((resolve, reject) => {
      let url = payload ? state.api_url + '?project=' + payload['@id'] : state.api_url
      dispatch('tools/getFromAPI', {url}, {root: true})
        .then((response) => {
          if (response.status === 200) {
            commit('replaceScheduledTracks', response.data['hydra:member']);
            resolve(response.status);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            AuthService.logout();
            reject(error.status);
          } else {
            reject(error.status);
          }
        })
    })
  },
  clearScheduledTracks({commit}){
    commit('clearScheduledTracks')
  },
  removeScheduledTrack({dispatch, commit, state}, scheduledTrackId) {
    return new Promise((resolve, reject) => {
      dispatch('tools/deleteFromAPI', {url: state.api_url+'/'+scheduledTrackId}, {root: true})
        .then((response) => {
          if(response.status === 204) {
            commit('removeScheduledTrack', scheduledTrackId)
            resolve(response.status)
          } else {
            reject(response)
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            AuthService.logout();
            reject(error.status);
          } else {
            reject(error.status);
          }
        })
    })
  },
  updateScheduledTrack({dispatch, commit, state}, payload){
    return new Promise( (resolve, reject) => {
      dispatch('tools/putToAPI', {url: state.api_url+'/'+payload.scheduledTrackId, data: payload.data}, {root: true})
        .then((response) => {
          if(response.status === 200) {
            commit('removeScheduledTrack', response.data.id);
            commit('pushToScheduledTrack', response.data);
            resolve(response.status)
          }else{
            reject(response.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createScheduledTrack({dispatch, commit, state}, scheduledTrack) {
    return new Promise((resolve, reject) => {
      dispatch('tools/postToAPI', {url: state.api_url, data:scheduledTrack}, {root: true})
        .then((response) => {
          if(response.status === 201) {
            commit('pushToScheduledTrack', response.data['hydra:member']);
            resolve(response.status)
          } else {
            reject(response.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

}

//mutations
const mutations = {
  replaceScheduledTracks (state, scheduledTracks) {
    state.scheduledTracks = scheduledTracks
  },
  clearScheduledTracks (state) {
    state.scheduledTracks = []
  },
  removeScheduledTrack (state, scheduledTrackId) {
    state.scheduledTracks = state.scheduledTracks.filter(el => el.id !== scheduledTrackId)
  },
  pushToScheduledTrack (state, scheduledTrack) {
    state.scheduledTracks.push(scheduledTrack)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
