import Axios from 'axios';

//state
const state = () => ({
  me : {},
  users: [],
  api_url_users: process.env.VUE_APP_API_ROOT+"/api/users",
  api_url_me: process.env.VUE_APP_API_ROOT+"/api/users/me"
})

// getters
const getters = {
  login : state => state.login,
  me : state => state.me,
  users: state => state.users
}

//actions
const actions = {
  getCurrentUser({dispatch, commit, state}) {
    return new Promise((resolve, reject) => {
      dispatch('tools/getFromAPI', {url:state.api_url_me}, {root: true})
        .then((response)=>{
          if(response.status === 200){
            commit('setUser', response.data);
            resolve(response.status);
          }else{
            reject(response.status);
          }
        })
        .catch((error)=>{
          reject(error)
        })
    })
  },

  loadUsers({dispatch, commit, state}){
    return new Promise((resolve, reject) => {
      dispatch('tools/getFromAPI',{url:state.api_url_users}, {root: true})
        .then((response) => {
          if(response.status === 200) {
            commit('replaceUsers', response.data['hydra:member']);
            resolve(response.status);
          }else{
            reject(response.status);
          }
        })
        .catch((error) => {
          reject(error);
        })
    })
  },
  updateUser({dispatch, commit, state}, payload){
    return new Promise((resolve, reject) => {
      dispatch('tools/putToAPI', {url: state.api_url_users+'/'+payload.username, data:payload.user }, {root: true})
        .then((response) => {
          if(response.status === 200){
            commit('removeFromUsers', response.data.id)
            commit('pushToUsers',response.data)
            resolve(response.status)
          }else{
            reject(response.status)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteUser({dispatch, commit, state}, userId){
    return new Promise((resolve, reject) => {
      dispatch('tools/deleteFromAPI', {url: state.api_url_users+'/'+userId}, {root: true})
        .then((response)=>{
          if(response.status === 204){
            commit('removeFromUsers', userId)
            resolve(response.status)
          }else{
            reject(response.status)
          }
        })
        .catch((error)=>{
          reject(error.status)
        })
    })
  },
}


//mutations
const mutations = {
  setLogin(state, login){
    state.login = login;
  },
  setUser(state, user){
    state.me=JSON.parse(JSON.stringify(user))
  },
  replaceUsers(state, users) {
    state.users = users
  },
  pushToUsers(state, user) {
    state.users.push(user);
  },
  removeFromUsers(state, userId) {
    state.users = state.users.filter( el => el.id !== userId);
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
