import Axios from 'axios';
import AuthService from "../../services/AuthService";

//state
const state = () => ({
  statuses : [],
  api_url: process.env.VUE_APP_API_ROOT+"/api/statuses"
})

// getters
const getters = {
  statuses: state => state.statuses,
}

//actions
const actions = {
  loadStatuses({dispatch, commit, state}){
    return new Promise((resolve, reject) => {
      dispatch('tools/getFromAPI', {url:state.api_url}, {root: true})
        .then((response) => {
          if(response.status === 200) {
            commit('replaceStatuses', response.data['hydra:member']);
            resolve(response.status);
          } else {
            reject(response.status)
          }
        })
        .catch((error)=>{
          if(error.response.status === 401) {
            AuthService.logout();
          }else{
            reject(error);
          }
        })
    })
  },
  addStatus({dispatch, commit, state}, status){
    return new Promise((resolve, reject) => {
      dispatch('tools/postToAPI', {url:state.api_url, data: status}, {root: true})
        .then((response)=>{
          if(response.status === 201){
            commit('pushToStatuses',response.data);
            resolve(response.status);
          } else {
            reject(response.status)
          }
        })
        .catch((error)=>{
          reject(error);
        })
    })
  },
  deleteStatus({dispatch, commit, state}, statusId){
    return new Promise((resolve, reject) => {
      dispatch('tools/deleteFromAPI', {url:state.api_url+'/'+statusId}, {root: true})
        .then((response)=>{
          if(response.status === 204){
            commit('removeFromStatuses', statusId);
            resolve(response.status);
          } else {
            reject(response.status)
          }
        })
        .catch((error) => {
          reject(error);
        });
    })
  },
  updateStatus({dispatch, commit, state}, payload){
    return new Promise((resolve, reject) => {
      dispatch('tools/putToAPI', {url:state.api_url+'/'+payload.statusId, data:payload.status}, {root: true})
        .then((response) => {
          if(response.status === 200){
            commit('removeFromStatuses',response.data.id);
            commit('pushToStatuses', response.data);
            resolve(response.status);
          } else {
            reject(response.status)
          }
        })
        .catch((error)=>{
          reject(error);
        })
    })

  },

}

//mutations
const mutations = {
  replaceStatuses(state, statuses){
    state.statuses = statuses;
  },
  pushToStatuses(state, status) {
    state.statuses.push(status);
  },
  removeFromStatuses(state, statusId) {
    state.statuses = state.statuses.filter( el => el.id !== statusId);
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
