import Axios from 'axios';
import AuthService from "../../services/AuthService";

//state
const state = () => ({
  tracks : [],
  api_url: process.env.VUE_APP_API_ROOT+"/api/tracks"
})

// getters
const getters = {
  tracks: state => state.tracks,
  getTrackByAtId : state => atId => state.tracks.filter(el => el['@id'] === atId)[0]
}

//actions
const actions = {
  loadTracks({dispatch, commit, state}) {
    return new Promise((resolve, reject) => {
      dispatch('tools/getFromAPI', {url: state.api_url+'?pagination=false'}, {root: true})
        .then((response) => {
          if(response.status === 200){
            commit('replaceTracks', response.data['hydra:member']);
            resolve(response.status)
          } else {
            reject(response.status)
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            AuthService.logout();
          } else {
            reject(error.response)
          }
        })
    })
  },
  addTrack({dispatch, commit, state}, track){
    return new Promise((resolve, reject)=>{
      dispatch('tools/postToAPI', {url: state.api_url, data: track}, {root: true})
        .then((response)=> {
          if(response.status === 201){
            commit('pushToTracks', response.data);
            resolve(response.status);
          } else {
            reject(response.status)
          }
        })
        .catch((error)=>{
          reject(error);
        })
    })
  },
  deleteTrack({dispatch, commit, state}, trackId){
    return new Promise((resolve, reject)=>{
      dispatch('tools/deleteFromAPI',{url:state.api_url+'/'+trackId}, {root: true})
        .then((response)=>{
          if(response.status === 204){
            commit('removeFromTracks', trackId);
            resolve(response.status)
          } else {
            reject(response.status)
          }
        })
        .catch((error)=>{
          reject(error)
        })
    })
  },
  updateTrack({dispatch, commit, state}, payload){
    return new Promise((resolve, reject)=>{
      dispatch('tools/putToAPI', {url:state.api_url+'/'+payload.trackId, data:payload.track}, {root: true})
        .then((response)=>{
          if(response.status === 200){
            commit('removeFromTracks', response.data.id);
            commit('pushToTracks', response.data);
            resolve(response.status);
          } else {
            reject(response.status)
          }
        })
        .catch((error)=>{
          reject(error);
        })
    })
  },
}

//mutations
const mutations = {
  replaceTracks(state, tracks){
    state.tracks = tracks;
  },
  pushToTracks(state, track) {
    state.tracks.push(track);
  },
  removeFromTracks(state, trackId) {
    state.tracks = state.tracks.filter( el => el.id !== trackId);
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
