//actions
import Axios from "axios";
import AuthService from "@/services/AuthService";

const actions = {
  getFromAPI({}, payload){
    return new Promise((resolve, reject)=>{
      Axios({
        url:payload.url,
        method: 'GET',
        withCredentials: true
      })
        .then((response)=>{resolve(response)})
        .catch((error) => {reject(error.response)})
    })
  },
  postToAPI({},payload){
    return new Promise((resolve, reject)=>{
      Axios({
        url: payload.url,
        method: 'POST',
        data: JSON.parse(JSON.stringify(payload.data)),
        withCredentials: true
      })
        .then((response)=>{resolve(response)})
        .catch((error) => {reject(error.response)})
    })
  },
  putToAPI({}, payload){
    return new Promise((resolve,reject)=>{
      Axios({
        url: payload.url,
        method: 'PUT',
        type: 'application/json',
        data: JSON.parse(JSON.stringify(payload.data)),
        withCredentials: true
      })
        .then((response)=>{resolve(response)})
        .catch((error)=>{reject(error.response)})
    })
  },
  deleteFromAPI({},payload) {
    return new Promise((resolve, reject) => {
      Axios({
        url: payload.url,
        method: 'DELETE',
        withCredentials: true
      })
        .then((response) => {resolve(response)})
        .catch((error)=>{reject(error.response)})
    })
  },
}

export default {
  namespaced: true,
  actions,
}
