var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',{attrs:{"me":_vm.me}},[_c('template',{slot:"links"},[_c('hr',{staticClass:"my-3"}),_c('sidebar-item',{attrs:{"link":{
            name: 'Dashboard',
            path: '/dashboard',
            icon: 'ni ni-tv-2 text-red',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Parcours',
            path: '/tracks',
            icon: 'ni ni-square-pin text-green',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Projets',
            path: '/projects',
            icon: 'ni ni-collection text-yellow',
          }}}),_c('sidebar-item',{attrs:{"link":{
                    name: 'Cartes',
                    path: '/maps',
                    icon: 'ni ni-map-big text-blue'
                  }}})],1)],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType,"me":_vm.me}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('base-header',{staticClass:"pb-6 pb-8 pt-2"},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }