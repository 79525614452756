import AuthService from "../../services/AuthService";

//state
const state = () => ({
  projects : [],
  api_url: process.env.VUE_APP_API_ROOT+"/api/projects"
})

// getters
const getters = {
  projects : state => state.projects
}

//actions
const actions = {
  loadProjects({dispatch, commit, state}) {
    return new Promise((resolve, reject) => {
      dispatch('tools/getFromAPI',{url:state.api_url}, {root: true})
        .then((response) => {
          if(response.status === 200){
            commit('replaceProjects', response.data['hydra:member']);
            resolve(response.status);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            AuthService.logout();
            reject(error.status);
          } else {
            reject(error.status);
          }
        })
    })
  },
  addProject({dispatch, commit, state}, project){
    return new Promise((resolve, reject) => {
      dispatch('tools/postToAPI', {url:state.api_url, data: project},{root: true})
        .then((response) => {
          if(response.status === 201){
            commit('pushToProjects',response.data);
            resolve(response.status);
          }else{
            reject(response.status);
          }
        })
        .catch((error)=>{
          reject(error.status);
        })
    })
  },
  updateProject({dispatch, commit, state}, payload) {
    return new Promise((resolve, reject) => {
      dispatch('tools/putToAPI', {url: state.api_url+'/'+payload.projectId, data:payload.project}, {root: true})
        .then((response) => {
          if(response.status === 200) {
            commit('removeProject', response.data.id)
            commit('pushToProjects', response.data)
            resolve(response.status)
          } else { reject(response.status) }
        })
        .catch((error) => { reject(error) })
    })
  },
  deleteProject({dispatch, commit, state}, projectId){
    return new Promise((resolve, reject) => {
      dispatch('tools/deleteFromAPI', {url:state.api_url+'/'+projectId}, {root:true})
        .then((response) => {
          if(response.status === 204){
            commit('removeProject', projectId)
            resolve(response.status)
          } else {
            reject(response.status)
          }
        })
        .catch((error) => {
          reject(error.response)
        })
    })
  },
}

//mutations
const mutations = {
  replaceProjects (state,projects) {
    state.projects = projects;
  },
  pushToProjects (state, project) {
    state.projects.push(project);
  },
  removeProject (state, projectId) {
    state.projects = state.projects.filter(el => el.id !== projectId)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
