<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar :me="me">
      <template slot="links">
        <hr class="my-3">
        <sidebar-item
          :link="{
            name: 'Retour au site',
            path: '/dashboard',
            icon: 'ni ni-tv-2 text-red',
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Utilisateurs',
            path: '/admin/users',
            icon: 'ni ni-single-02 text-yellow',
          }"
        >
        </sidebar-item>
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType" :me="me"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <!--<fade-transition :duration="200" origin="center top" mode="out-in">
           your content here -->
        <base-header class="pb-6 pb-8 pt-2">
          <router-view></router-view>
        </base-header>
        <!--        </fade-transition>-->
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from './DashboardNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import DashboardContent from './Content.vue';
import { FadeTransition } from 'vue2-transitions';
import {mapActions, mapGetters} from 'vuex';

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    DashboardContent,
    FadeTransition
  },
  methods: {
    ...mapActions({
        getCurrentUser : 'users/getCurrentUser'
      }),
    initScrollbar() {
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        initScrollbar('sidenav');
      }
    }
  },
  mounted() {
    this.initScrollbar()
  },
  computed:{
    ...mapGetters({
      me : 'users/me',
    })
  },
};
</script>
<style lang="scss">
</style>
