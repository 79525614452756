import axios from 'axios';
import store  from '@/store/store';

const API_URL_token = process.env.VUE_APP_API_ROOT+"/api/token/generate";
const API_URL_refreshToken = process.env.VUE_APP_API_ROOT+"/api/token/refresh"
const API_URL_logout = process.env.VUE_APP_API_ROOT+"/api/token/invalidate";
const API_URL_users = process.env.VUE_APP_API_ROOT+"/api/users";
const API_URL_me = process.env.VUE_APP_API_ROOT+"/api/users/me";

class AuthService {
  login(user) {
    return new Promise((resolve, reject) => {
      const config = {'headers': {'Access-Control-Allow-Origin': '*'}};
      return axios({
        method: 'POST',
        url: API_URL_token,
        data: {
          username: user.username,
          password: user.password
        },
        credentials: "include",
        withCredentials: true
      })
      .then(response => {
          if (response.status === 204){
            store.dispatch('users/getCurrentUser');
            resolve(response.status)
          }else{
            reject(response.status)
          }
        })
        .catch( (error => {
          reject(error.response)
        }));
    })
  }

  refreshToken() {
    return new Promise((resolve, reject) => {
      const config = {'headers': {'Access-Control-Allow-Origin': '*'}};
      return axios({
        method: 'POST',
        url: API_URL_refreshToken,
        data: {
          "refresh_token": JSON.parse(localStorage.getItem('user')).refresh_token
        },
        config: config
      })
      .then(response => {
        if (response.data.token) {
          let userinfo = response.data;
          userinfo.name = JSON.parse(localStorage.getItem('user')).name;
          userinfo.timestamp = Date.now() + 3600000;
          localStorage.setItem('user', JSON.stringify(userinfo));
          resolve(response.status)
        }
      })
      .catch( (error => {
        reject(error)
      }));
    })
  }

  logout() {
    return new Promise((resolve) => {
      localStorage.removeItem('user');
      resolve({value: 'ok', status: 200})
    })
  }

  register(user) {
    return new Promise((resolve, reject) => {
      axios({
        url: API_URL_users,
        method: 'POST',
        data: user
      })
        .then(response => {
          if(response.status === 201){
            resolve(response.status);
          } else {
            reject(response.status)
          }
        })
        .catch((error)=>{
          reject(error);
        })
    })
  }

  parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  currentUser () {
    axios({
      url: API_URL_me,
      method: 'GET',
      withCredentials: true
    })
    .then((response) => {
      if(response.status === 200) {
        return response.data;
      }else{
        return null
      }
    })
    .catch(() => {return null})
  }
}

export default new AuthService();
